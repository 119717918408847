@font-face {
  font-family: "Neue Plak";
  src: url("NeuePlak-ExtendedRegular.eot");
  src: url("NeuePlak-ExtendedRegular.eot?#iefix") format("embedded-opentype"), url("NeuePlak-ExtendedRegular.woff2") format("woff2"),
    url("NeuePlak-ExtendedRegular.woff") format("woff"), url("NeuePlak-ExtendedRegular.ttf") format("truetype"),
    url("NeuePlak-ExtendedRegular.svg#NeuePlak-ExtendedRegular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Plak Text";
  src: url("NeuePlakText-Light.eot");
  src: url("NeuePlakText-Light.eot?#iefix") format("embedded-opentype"), url("NeuePlakText-Light.woff2") format("woff2"),
    url("NeuePlakText-Light.woff") format("woff"), url("NeuePlakText-Light.ttf") format("truetype"),
    url("NeuePlakText-Light.svg#NeuePlakText-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Plak";
  src: url("NeuePlak-ExtendedThin.eot");
  src: url("NeuePlak-ExtendedThin.eot?#iefix") format("embedded-opentype"), url("NeuePlak-ExtendedThin.woff2") format("woff2"),
    url("NeuePlak-ExtendedThin.woff") format("woff"), url("NeuePlak-ExtendedThin.ttf") format("truetype"),
    url("NeuePlak-ExtendedThin.svg#NeuePlak-ExtendedThin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Plak";
  src: url("NeuePlak-ExtendedLight.eot");
  src: url("NeuePlak-ExtendedLight.eot?#iefix") format("embedded-opentype"), url("NeuePlak-ExtendedLight.woff2") format("woff2"),
    url("NeuePlak-ExtendedLight.woff") format("woff"), url("NeuePlak-ExtendedLight.ttf") format("truetype"),
    url("NeuePlak-ExtendedLight.svg#NeuePlak-ExtendedLight") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
